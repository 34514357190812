<template>
  <section class="intro-x">
    <div class="w-ful flex justify-end mt-5">
      <!-- <div class="capitalize font-bold text-xl">
        Proveedores
      </div> -->
      <router-link
      v-if="$can('pharmasan.administrativa.compras-administrativas.proveedores.access')"
      :to="{name: 'pharmasan.administrativa.compras-administrativas.proveedores.carga-masiva'}"
      class="btn btn-primary capitalize flex items-center"
      >
        <span class="mr-2">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
          </svg>
        </span>
        <span style="position:relative; top:1px;">Carga masiva </span>
      </router-link>
      <button
      type="button"
      class="btn bg-green-600 ml-2 text-white"
      @click="handleExport($route.query)"
      >
        <svg viewBox="0 0 24 24" style="width: 24px; height: 24px;"><path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z"></path></svg>
        <span class="ml-1"> Descargar </span>
      </button>
    </div>

    <div class="font-bold -5">
      Filtros:
    </div>
    <div
    class="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mb-5 box p-4"
    >
      <!-- proveedore -->
      <div>
        <label for="supplierId">Proveedor</label>
        <div class="p-inputgroup">
          <AutoComplete
          v-model="model.supplier"
          :suggestions="filteredSuppliers"
          :dropdown="true"
          field="cardname"
          inputClass="form-control"
          @complete="searchSuppliers($event)"
          @item-select="articleList($event,1)"
          >
            <template #item="{ item }">
              <div>
                <span>{{item.cardname}}</span>
              </div>
            </template>
          </AutoComplete>
        </div>
        <messageError :text="errors.supplier" />
      </div>
      <!-- articulo -->
      <div>
        <label for="">Articulo</label>
        <div class="p-inputgroup">
          <AutoComplete
          v-model="model.article"
          :suggestions="filteredProducts"
          field="name"
          inputClass="form-control"
          @complete="searchProduct($event)"
          @item-select="articleList($event,2)"
          >
            <template #item="{ item }">
              <div>
                <span>{{item.name}}</span>
              </div>
            </template>
          </AutoComplete>
        </div>
        <messageError :text="errors.article" />
      </div>
      <!-- botones -->
      <div>
        <button
        type="btton"
        class="btn btn-warning"
        @click="onFilterClear"
        >
          Limpiar filtros
        </button>
      </div>
    </div>

    <div class="w-full">
      <DataTable @sort="orderFiled" :value="getItems" class="p-datatable-sm overflow-x-auto">
        <Column
        v-if="$can('pharmasan.administrativa.compras-administrativas.proveedores.access')"
        header=""
        headerStyle="width: 40px;"
        >
          <template #body="{ data }">
            <button
            type="button"
            class="btn btn-primary py-1 px-2"
            @click="handleEditar(data)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
            </button>
          </template>
        </Column>
        <Column
        header="Cod. Articulo"
        field="code"
        sortable
        headerStyle="width: 120px;"
        />
        <Column
        header="Articulo"
        field="name"
        headerStyle="width:250px; "
        sortable />
        <Column
        header="Proveedor"
        field="cardname"
        headerStyle="width:250px; "
        sortable
        />
        <Column
        header="Precio"
        field="price"
        headerStyle="width: 120px;"
        sortable
        >
            <template #body="{data}">
              <span>
                {{$h.formatCurrency(data.price,2)}}
              </span>
            </template>
        </Column>

      </DataTable>
    </div>
    <div class="w-full">
      <Paginator
      :rows="getPaginate.perPage"
      :totalRecords="getPaginate.total"
      :first="getPaginate.first"
      :rowsPerPageOptions="[10,20,30]"
      @page="onPage($event)"
      />
    </div>

    <ProductModal @refresh="fetchResult" />

  </section>
</template>

<script>
import articlePresentationSuppliersFind from '../../../../services/articlePresentationSuppliersFind'
import suppliersFindName from '../../../../services/suppliersFindName'
import findNameArticle from '../../../../services/findNameArticle'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import AutoComplete from 'primevue/autocomplete'
import { useForm, useField } from 'vee-validate'
import { object, string } from 'yup'
import { computed, onMounted, ref, watch, reactive, defineAsyncComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useProveedores from './useProveedores'

export default {
  name: 'Proveedores',
  components: {
    DataTable,
    Column,
    Paginator,
    AutoComplete,
    ProductModal: defineAsyncComponent(() => import('../components/productModal'))
  },
  setup () {
    const route = useRoute()
    const router = useRouter()

    const { handleEditar, handleExport } = useProveedores()

    const onPage = (row) => {
      const page = row.page + 1
      const paginate = row.rows
      const queryParams = { ...route.query }
      const first = row.first
      router.push({
        query: {
          ...queryParams,
          page,
          first,
          paginate,
          supplierId: model.supplier ? model.supplier.cardcode : null,
          articleId: model.article ? model.article.id : null
        }
      })
    }

    const filteredSuppliers = ref([])
    const filteredProducts = ref([])
    const items = reactive({
      total: 0,
      page: 1,
      data: [],
      per_page: 0
    })
    const getPaginate = computed(() => {
      return {
        perPage: items.per_page,
        total: items.total,
        first: +route.query.first || 1
      }
    })
    const getItems = computed(() => {
      return items.data
    })
    const validationSchema = object().shape({
      supplier: object()
        .shape({
          cardcode: string().required().label('código del proveedor'),
          cardname: string().required().label('nombre del proveedor')
        }).required().nullable().label('nombre del proveedor'),
      article: object()
        .shape({
          id: string().required().label('código del articulo'),
          name: string().required().label('nombre del articulo')
        }).required().nullable().label('nombre del articulo')
    })
    const { values: model, handleReset, errors } = useForm({
      validationSchema
    })
    useField('supplier', null, { initialValue: null })
    useField('article', null, { initialValue: null })

    const articleList = (evt, tipo = 1) => {
      // const queryParams = {
      //   supplierId: model.supplier ? model.supplier.cardcode : null,
      //   articleId: model.article ? model.article.id : null,
      //   ...route.query
      // }
      // if (!queryParams.page) queryParams.page = 1
      const routeParam = {}
      if (tipo === 1) { // si es proveedor
        routeParam.supplierId = evt.value.cardcode
      } else if (tipo === 2) { // si es producto
        routeParam.articleId = evt.value.id
      }

      _routerPublish(routeParam)
    }
    const searchSuppliers = ({ query }) => {
      if (!query.trim() || query.length < 3) {
        filteredSuppliers.value = []
      } else {
        return suppliersFindName({ query: query.trim() }).then(({ data }) => {
          filteredSuppliers.value = data.data
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    const _routerPublish = (obj = {}) => {
      const { query } = route
      const queryParams = { ...query, ...obj }
      router.push({ query: { ...queryParams } })
    }
    const searchProduct = ({ query }) => {
      if (!query.trim() || query.length < 3) {
        filteredProducts.value = []
      } else {
        return findNameArticle({ name: query.trim() }).then(({ data }) => {
          filteredProducts.value = data
        }).catch(err => {
          console.error(err.message)
        })
      }
    }

    const fetchResult = () => {
      const queryParams = { ...route.query }
      queryParams.page = queryParams.page ? queryParams.page : 1
      queryParams.paginate = queryParams.paginate ? queryParams.paginate : 10
      articlePresentationSuppliersFind(queryParams).then(({ data }) => {
        items.total = data.total
        items.page = data.current_page
        items.data = data.data
        items.per_page = data.per_page
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onFilterClear = () => {
      handleReset()
      router.push({ query: {} })
    }

    const getImage = ({ cardname }) => {
      return `https://ui-avatars.com/api/?background=125386&color=fff&name=${cardname}`
    }

    const orderFiled = ({ sortField, sortOrder }) => {
      _routerPublish({ order: `${sortField},${sortOrder}` })
    }

    watch(() => route.query, (value) => {
      fetchResult()
    })

    onMounted(() => {
      fetchResult()
      setTimeout(() => {
        handleReset()
      }, 200)
    })
    return {
      model,
      errors,
      onPage,
      getPaginate,
      getItems,
      filteredProducts,
      filteredSuppliers,
      searchSuppliers,
      searchProduct,
      articleList,
      articlePresentationSuppliersFind,
      onFilterClear,
      handleEditar,
      getImage,
      fetchResult,
      handleExport,
      orderFiled
    }
  }
}
</script>
