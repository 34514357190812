import { ref } from 'vue'
import { messageSuccess } from '../../../../../../../../libs/mensajes'
import proveedorPrecioActualizarService from '../../../../../services/proveedorPrecioActualizarService'

const isVisible = ref(false)

const supplier = ref({
  id: null,
  price: 0,
  article: {}
})

const isLoading = ref(false)

const useProductModal = () => {
  const model = ref({
    price: 0,
    justification: ''
  })
  const toggle = () => {
    isVisible.value = !isVisible.value
  }

  const setSupplier = (valueObject = {}) => {
    for (const [key, value] of Object.entries(valueObject)) {
      supplier.value[key] = value
    }
  }

  const handleSubmit = () => {
    isLoading.value = true
    proveedorPrecioActualizarService(supplier.value.id, model.value).then(({ data }) => {
      isLoading.value = false
      toggle()
      messageSuccess('Actualizado con exito')
    }).catch(err => {
      isLoading.value = false
      toggle()
      console.error(err.message)
    })
  }
  return {
    isLoading,
    supplier,
    setSupplier,
    model,
    isVisible,
    toggle,
    handleSubmit
  }
}

export default useProductModal
