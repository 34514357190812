import useProductModal from '../components/productModal/useProductModal'
import proveedoresExportService from '../../../../services/proveedoresExportService'
import { messageWarning } from '../../../../../../../libs/mensajes'

const useProveedores = () => {
  const { toggle, setSupplier } = useProductModal()

  const handleEditar = (value) => {
    setSupplier(value)
    toggle()
  }

  const handleExport = ({ supplierId, articleId }) => {
    if (!supplierId && !articleId) {
      messageWarning('Es necesario seleccionar un proveedor o articulo antes de continuar')
      return false
    }
    const url = proveedoresExportService({ supplierId, articleId })
    window.open(url, '_blank')
  }

  return {
    handleEditar,
    handleExport
  }
}

export default useProveedores
